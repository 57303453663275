<template>
  <b-card>
    <template #header>
      <h3 class="text-capitalize">
        {{ $t('label.proofs') }}
      </h3>
    </template>

    <AppImageViewer
      v-if="proofs.length"
      :value="proofs"
      isMultiple
    ></AppImageViewer>
    <div v-else>
      {{ $t('message.thereAreNoItemsToShow') }}
    </div>
  </b-card>
</template>

<script>
  import AppImageViewer from '@/views/components/bases/image-viewer/AppImageViewer';

  export default {
    name: 'FlagProofsCard',
    components: {
      AppImageViewer
    },
    props: {
      value: {
        type: Object,
        default: () => ({})
      }
    },
    data: () => ({
      proofs: []
    }),
    watch: {
      value() {
        if (this.value) {
          this.importProofs(this.value);
        }
      }
    },
    methods: {
      importProofs(data) {
        if (data.proofs) {
          this.proofs = data.proofs.map((x) => {
            return x.mediaPath;
          });
        }
      }
    }
  };
</script>

<style></style>
