<template>
  <b-card class="flag-content-card">
    <!-- begin:: Card header -->
    <template #header>
      <div class="text-capitalize font-size-h3 font-weight-bold">
        {{ $t('label.content') }}
      </div>
    </template>
    <!-- end:: Card header -->

    <!-- begin:: Card Body -->
    <AppDescription :contentHolder="contents" :value="value" type="default">
      <!-- begin:: Media Slot -->
      <template v-slot:media>
        <AppMediaViewer :value="value.media" :grid="mediaGrid"></AppMediaViewer>
      </template>
      <!-- end:: Media Slot -->
    </AppDescription>
    <!-- end:: Card Body -->
  </b-card>
</template>

<script>
  import AppMediaViewer from '@/views/components/bases/AppMediaViewer';
  import AppDescription from '@/views/components/bases/AppDescription';
  import { FLAG_CONTENT } from '@/core/constants/enums';
  import { i18nHelper } from '@/core/utils';

  export default {
    name: 'FlagContentCard',
    components: {
      AppDescription,
      AppMediaViewer
    },
    props: {
      value: {
        type: Object,
        default: () => {},
        required: true
      },
      content: {
        type: String,
        required: true
      }
    },
    data: () => ({
      contents: [],
      mediaGrid: {
        cols: '12',
        md: '6',
        lg: '6'
      }
    }),
    mounted() {
      this.mountFlagContentCard();
    },
    methods: {
      mountFlagContentCard() {
        var contents = [];
        var currentContentType = this.content;

        if (currentContentType == FLAG_CONTENT.USER) {
          contents = [
            {
              head: i18nHelper.getMessage('label.firstName'),
              body: {
                key: 'firstName'
              }
            },
            {
              head: i18nHelper.getMessage('label.lastName'),
              body: {
                key: 'lastName'
              }
            },
            {
              head: i18nHelper.getMessage('label.name'),
              body: {
                key: 'name'
              }
            },
            {
              head: i18nHelper.getMessage('label.role'),
              body: {
                key: 'role'
              }
            }
          ];
        } else if (currentContentType == FLAG_CONTENT.CASTING) {
          contents = [
            {
              head: i18nHelper.getMessage('label.title'),
              body: {
                key: 'title'
              }
            },
            {
              head: i18nHelper.getMessage('label.deletedAt'),
              body: {
                key: 'deletedAt'
              }
            }
          ];
        } else if (currentContentType == FLAG_CONTENT.NEWSFEED) {
          contents = [
            {
              head: i18nHelper.getMessage('label.postOwner'),
              body: {
                key: 'postOwner'
              }
            },
            {
              head: i18nHelper.getMessage('label.title'),
              body: {
                key: 'content'
              }
            },
            {
              head: i18nHelper.getMessage('label.media'),
              body: {
                key: 'media',
                slotName: 'media'
              }
            },
            {
              head: i18nHelper.getMessage('label.deletedAt'),
              body: {
                key: 'deletedAt'
              }
            }
          ];
        } else if (currentContentType == FLAG_CONTENT.REVIEW) {
          contents = [
            {
              head: i18nHelper.getMessage('label.reviewer'),
              body: {
                key: 'reviewer'
              }
            },
            {
              head: i18nHelper.getMessage('label.reviewee'),
              body: {
                key: 'reviewee'
              }
            },
            {
              head: i18nHelper.getMessage('label.castingJobOwner'),
              body: {
                key: 'castingJobOwner'
              }
            },
            {
              head: i18nHelper.getMessage('label.rating'),
              body: {
                key: 'rating'
              }
            },
            {
              head: i18nHelper.getMessage('label.comment'),
              body: {
                key: 'comment'
              }
            },
            {
              head: i18nHelper.getMessage('label.deletedAt'),
              body: {
                key: 'deletedAt'
              }
            }
          ];
        }

        this.contents = contents;
      }
    }
  };
</script>

<style></style>
