<template>
  <div>
    <b-row no-gutters>
      <!--begin: Col 1-->
      <b-col cols="12" md="4">
        <FlagOverviewCard :value="flagObj"></FlagOverviewCard>
      </b-col>
      <!--end: Col 1-->

      <!--begin: Col 2-->
      <b-col cols="12" md="8" class="mt-4 mt-md-0 pl-md-4 pl-lg-8">
        <FlagContentCard
          :value="flagObj.content"
          :content="content"
          class="mb-4"
        ></FlagContentCard>

        <FlagProofsCard :value="flagObj"></FlagProofsCard>
      </b-col>
      <!--end: Col 2-->
    </b-row>
  </div>
</template>

<script>
  import FlagOverviewCard from '@/views/components/flag/FlagOverviewCard';
  import FlagProofsCard from '@/views/components/flag/FlagProofsCard';
  import FlagContentCard from '@/views/components/flag/FlagContentCard';
  import commonMixin from '@/core/mixins/common.mixin';
  import { ROUTE_NAME } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import flagMixin from '@/core/mixins/flag.mixin';
  import {
    FLAG_GET_FLAG,
    FLAG_INITIAL_GET_FLAG_STATE
  } from '@/core/store/flag.module';

  export default {
    name: 'FlagDetails',
    components: {
      FlagOverviewCard,
      FlagProofsCard,
      FlagContentCard
    },
    mixins: [commonMixin, flagMixin],
    props: {
      content: {
        type: String,
        required: true
      }
    },
    data: () => ({
      id: null,
      flagObj: {
        content: {}
      }
    }),
    computed: {
      flagComplete() {
        return this.$store.state.flag.flag.complete;
      }
    },
    watch: {
      flagComplete() {
        let response = this.$store.state.flag.flag;
        let title = i18nHelper.getMessage('label.getFlag');
        let initialStateAction = FLAG_INITIAL_GET_FLAG_STATE;
        let successAction = (response) => {
          this.getFlagSucceedAction(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    created() {
      this.initFlagMemberDetails();
    },
    methods: {
      refreshFlagMemberDetailsAction() {
        this.getFlag(this.id);
      },
      getFlagSucceedAction(response) {
        let data = response.data;

        this.flagObj = data;
      },
      getFlag(id) {
        this.$store.dispatch(FLAG_GET_FLAG, {
          id
        });
      },
      initFlagMemberDetails() {
        var content = this.content;
        var id = Number(this.$route.params.id);
        var breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.flag'),
              route: { name: ROUTE_NAME.FLAG }
            },
            {
              title: i18nHelper.getMessage(`enumFlagContent.${content}`),
              route: { name: this.getFlagRouteName(content) }
            },
            {
              title: i18nHelper.getMessage(`enumFlagContentDetails.${content}`)
            }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };

        this.id = id;
        this.getFlag(id);
        this.initBreadCrumb(breadcrumbData);
        window.KTUtil.scrollTop();
      }
    }
  };
</script>

<style lang="scss"></style>
