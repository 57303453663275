<template>
  <div class="app-image-viewer">
    <template v-if="!isMultiple">
      <div
        v-if="value"
        class="images image-container cursor-pointer"
        @click="show(value)"
      >
        <b-img :src="value" class="w-100 single-img image" :class="imageClass">
        </b-img>
        <div class="middle">
          <div class="text">
            {{ $t('label.clickToView') }}
          </div>
        </div>
      </div>
      <slot v-else name="placeholder" />
    </template>
    <template v-else>
      <div class="images">
        <b-row no-gutters>
          <template v-for="(i, index) in value">
            <b-col
              :key="index"
              cols="12"
              md="6"
              lg="4"
              class="mb-4 mb-md-0 p-md-2 image-container cursor-pointer"
              @click="show(value)"
            >
              <b-img :src="i" class="multiple-img image w-100"> </b-img>
              <div class="middle">
                <div class="text">{{ $t('label.clickToView') }}</div>
              </div>
            </b-col>
          </template>
        </b-row>
      </div>
    </template>
  </div>
</template>

<script>
  export default {
    name: 'AppImageViewer',
    props: {
      value: {},
      isMultiple: {
        type: Boolean,
        default: false
      },
      imageClass: {
        type: String,
        default: ''
      }
    },
    methods: {
      show(value) {
        this.$viewerApi({
          images: this.isMultiple ? value : [value]
        });
      }
    }
  };
</script>

<style lang="scss">
  .app-image-viewer {
    .single-img {
      object-fit: cover;
      max-height: 375px;
    }
    .multiple-img {
      object-fit: cover;
      height: 150px;
    }

    .image-container {
      position: relative;
    }

    .image {
      opacity: 1;
      display: block;
      transition: 0.3s ease;
      backface-visibility: hidden;
    }

    .middle {
      min-width: 100px;
      transition: 0.3s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }

    .image-container:hover .image {
      opacity: 0.2;
    }

    .image-container:hover .middle {
      opacity: 1;
    }

    .text {
      text-align: center;
      color: #000000;
      font-size: 14px;
      padding: 4px;
    }
  }
</style>
