<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <h3 class="text-capitalize">{{ $t('label.overview') }}</h3>

      <!-- begin::Action Dropdown List -->
      <div v-if="value.status == flagStatus.PENDING">
        <b-dropdown variant="primary" :text="$t('label.actions')">
          <template v-for="(item, index) in actions">
            <b-dropdown-item
              :key="index"
              :variant="item.variant"
              @click="updateFlagStatus(item.value)"
            >
              {{ $t(`label.${item.label}`) }}
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </div>
      <!-- end::Action Dropdown List -->
    </div>

    <section class="pt-8">
      <template v-for="(i, index) in basicInfoList">
        <div :key="index" class="mb-4">
          <div class="font-weight-bolder text-capitalize mb-2">
            {{ $t(`label.${i.name}`) }}:
          </div>
          <div class="text-end text-gray-600">
            <div v-if="i.component">
              <component
                :is="i.component"
                :value="value[i.key]"
                :config="i.config"
              ></component>
            </div>
            <div v-else>
              {{ value[i.key] || '-' }}
            </div>
          </div>
        </div>
        <div
          v-if="i.key == 'comment'"
          :key="index + 's'"
          class="separator separator-dashed my-6"
        ></div>
      </template>
    </section>
  </b-card>
</template>

<script>
  import AppEnumLabel from '@/views/components/bases/AppEnumLabel';
  import commonMixin from '@/core/mixins/common.mixin';
  import { i18nHelper } from '@/core/utils';
  import { FLAG_STATUS } from '@/core/constants/enums';
  import {
    FLAG_UPDATE_FLAG,
    FLAG_INITIAL_UPDATE_FLAG_STATE
  } from '@/core/store/flag.module';

  export default {
    name: 'FlagOverviewCard',
    components: {
      AppEnumLabel
    },
    mixins: [commonMixin],
    props: {
      value: {
        type: Object,
        default: () => ({})
      }
    },
    data: () => ({
      flagStatus: FLAG_STATUS,
      basicInfoList: [
        {
          key: 'reportedBy',
          name: 'reportedBy'
        },
        {
          key: 'type',
          name: 'type'
        },
        {
          key: 'status',
          name: 'status',
          component: 'AppEnumLabel',
          config: {
            size: 'lg',
            enumKey: 'enumFlagStatus',
            statusColor: (value) => {
              let color = {
                [FLAG_STATUS.PENDING]: 'primary',
                [FLAG_STATUS.APPROVED]: 'success',
                [FLAG_STATUS.REJECTED]: 'danger'
              };

              return color[value];
            }
          }
        },
        {
          key: 'comment',
          name: 'comment'
        },
        {
          key: 'createdAt',
          name: 'createdAt'
        },
        {
          key: 'updatedAt',
          name: 'updatedAt'
        }
      ],
      actions: [
        {
          value: FLAG_STATUS.APPROVED,
          label: 'approve',
          variant: ''
        },
        {
          value: FLAG_STATUS.REJECTED,
          label: 'reject',
          variant: 'danger'
        }
      ]
    }),
    computed: {
      updateFlagComplete() {
        return this.$store.state.flag.updateFlag.complete;
      }
    },
    watch: {
      updateFlagComplete() {
        let response = this.$store.state.flag.updateFlag;
        let title = i18nHelper.getMessage('label.updateFlag');
        let initialStateAction = FLAG_INITIAL_UPDATE_FLAG_STATE;
        let successAction = () => {
          this.updateFlagSucceedAction();
        };
        let errorAction = () => {};

        if (response.complete) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction,
            errorAction
          );
        }
      }
    },
    methods: {
      updateFlagSucceedAction() {
        this.$parent.refreshFlagMemberDetailsAction();
      },
      updateFlagStatus(value) {
        let data = {
          status: value
        };

        this.updateFlag(data, this.value.id);
      },
      updateFlag(data, id) {
        this.$store.dispatch(FLAG_UPDATE_FLAG, { data, id });
      }
    }
  };
</script>

<style></style>
